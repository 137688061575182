import React from 'react';
import { MLabel, MIcon } from 'src/theme';
import { PATH_VERSION3, PATH_SUB_DISTRIBUTOR } from 'src/routes/paths';

import HomeIcon from '@material-ui/icons/Home';


import { Icon } from '@iconify/react';
import bellFill from '@iconify-icons/eva/bell-fill';
import bxHome from '@iconify/icons-bx/bx-home';
import bxsOffer from '@iconify/icons-bx/bxs-offer';
import boxIcon from '@iconify/icons-la/box';
import creditCardOutlined from '@iconify/icons-ant-design/credit-card-outlined';
import fileInvoice from '@iconify/icons-la/file-invoice';
import moneyBill from '@iconify/icons-fa-solid/money-bill';

import doorIcon from '@iconify/icons-akar-icons/door';
// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  home: <Icon icon={bxHome} style={{ width: '2em', height: '2em'}}/>,
  distributorPortal: <Icon icon={doorIcon} style={{ width: '2em', height: '2em' }}/>,
  offers: <Icon icon={bxsOffer} style={{ width: '2em', height: '2em'}}/>,
  preOrder: <Icon icon={boxIcon} style={{ width: '2em', height: '2em' }}/>,
  payment: <Icon icon={creditCardOutlined} style={{ width: '2em', height: '2em' }}/>,
  order: <Icon icon={fileInvoice} style={{ width: '2em', height: '2em' }}/>,
  notifications: <Icon icon={bellFill} style={{ width: '2em', height: '2em' }}/>,
  creditNote: <Icon icon={moneyBill} style={{ width: '2em', height: '2em' }}/>
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: 'distributor portal',
        icon: ICONS.distributorPortal,
        href: PATH_VERSION3.general.root
      }
    ]
  },
  {
    subheader: 'subdistributor portal',
    items: [
      {
        title: 'home',
        icon: ICONS.home,
        href: PATH_SUB_DISTRIBUTOR.general.root,
      },
      {
        title: 'notifications',
        icon: ICONS.notifications,
        href: PATH_SUB_DISTRIBUTOR.notification.root
      },
      {
        title: 'offers',
        icon: ICONS.offers,
        href: PATH_SUB_DISTRIBUTOR.offers.root
      },
      {
        title: 'pre-order',
        icon: ICONS.preOrder,
        href: PATH_SUB_DISTRIBUTOR.preorder.root
      },
      {
        title: 'closed offers',
        icon: ICONS.offers,
        href: PATH_SUB_DISTRIBUTOR.closedOffers.root
      },
      {
        title: 'payment',
        icon: ICONS.payment,
        href: PATH_SUB_DISTRIBUTOR.payment.root
      },
      {
        title: 'overpayment',
        icon: ICONS.creditNote,
        href: PATH_SUB_DISTRIBUTOR.overpayment,
      },
      {
        title: 'order',
        icon: ICONS.order,
        href: PATH_SUB_DISTRIBUTOR.order
      }
    ]
  }
];

export default navConfig;
